import UserResource from 'common/services/resources/user.js'
export default {
	props: [],
	data:
		function () {
			return {
				CONSTANTS: 
					{
						YOUR_MAIL : this.$gettext('Your mail:'),
						YOUR_PASSWORD : this.$gettext('Your password:'),
						UNABLE_GENERATE_PWD : this.$gettext('Unable to generate a password.'),
						WRONG_IMG: require('@/assets/images/picto_wrong.png'),
						OK_IMG: require('@/assets/images/picto_ok.png')
					},
				msg: '',
				img: ''
			}
		},
    created: function () {
		this.eventHub.$emit('hideNavbar');
		this.eventHub.$on('setActivateMsg',this.setActivateMsg);
		this.eventHub.$on('setActivateImg',this.setActivateImg);
		this.generateNewPassword();
    },			
	methods: {
		generateNewPassword: function(){
			var hash = this.$router.currentRoute.query.hash;
			var region = this.$router.currentRoute.query.region;
			var _this = this;
			var msg = _this.CONSTANTS.UNABLE_GENERATE_PWD;
			var img = _this.CONSTANTS.WRONG_IMG;
			UserResource.generateNewPassword(hash,region).then(
				function(response) {
					msg = _this.CONSTANTS.YOUR_MAIL +' '+ response.data.login + '<br/><br/>' + _this.CONSTANTS.YOUR_PASSWORD + ' ' + response.data.password;
					_this.eventHub.$emit('setActivateMsg',msg);
					_this.eventHub.$emit('setActivateImg',_this.CONSTANTS.OK_IMG);
				}).catch(
				function(response) { 
					_this.eventHub.$emit('setActivateMsg',msg);	
					_this.eventHub.$emit('setActivateImg',img);	
			});
		},
		setActivateMsg: function(msg){
			this.msg = msg;
		},
		setActivateImg: function(img){
			this.img = img;
		}
	}
}
